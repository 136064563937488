import React from 'react';
import PropTypes from 'prop-types';

const Text = ({children}) => (
  <span className="text-white text-md w-3/4 text-center mb-5">{children}</span>
);

Text.propTypes = {
  children: PropTypes.string,
};

export const App = () => (
  <div className="App">
    <div className="min-w-screen min-h-screen bg-[url('bg-studio.jpg')] bg-cover bg-center">
      <div className="w-screen h-screen backdrop-contrast-75 backdrop-brightness-75 overflow-y-scroll">
        <div className="flex flex-col justify-center items-center min-h-full">
          <Text>
             Handmade In Harpenden has now closed following sale of the business.
          </Text>
          <Text>
            We would like to thank all our wonderful customers, stockists, corporate and hospitality clients
            for your support over the last six years.
          </Text>
          <Text>
            Without you we would not have achieved all the wonderful things we have together.
          </Text>
          <Text>
            Please do continue supporting small businesses and local manufacturing as together we can
            continue making a difference.
          </Text>
          <Text>From Henry {String.fromCharCode(43)} Farhana</Text>
        </div>
      </div>
    </div>
  </div>
);


